<template>
  <div class="">
    <div
      v-if="message !== '' && !registering"
      class="bg-white shadow-lg rounded-md px-5 py-6"
    >
      <div class="text-center">
        <h2 class="text-2xl font-bold mb-3">Registration Successful</h2>
        <p>
          Registration Successful, kindly check <b>{{ user_email }}</b> to
          verify your email
        </p>
        <p v-if="message !== ''" class="mb-3 block text-xs font-semibold">
          Didn't get an email?
          <a class="underline text-primary" @click.prevent="resendVerification"
            >Resend email</a
          >
        </p>
        <router-link to="signin" class="block text-xs text-gray-600 mt-4"
          >Login to my account</router-link
        >
      </div>
    </div>
    <div v-else class="bg-white shadow-lg rounded-md px-5 py-3 mt-32">
      <h2 class="text-2xl font-bold mb-5">Sign Up.</h2>

      <a-form-model
        ref="signupForm"
        :rules="rules"
        layout="vertical"
        :model="form"
      >
        <div class="grid grid-cols-2 gap-x-2">
          <a-form-model-item has-feedback label="First Name" prop="fname">
            <a-input
              v-model="form.fname"
              :disabled="registering"
              placeholder="First Name"
            />
          </a-form-model-item>
          <a-form-model-item has-feedback label="Last Name" prop="lname">
            <a-input
              v-model="form.lname"
              :disabled="registering"
              placeholder="Last Name"
            />
          </a-form-model-item>
        </div>
        <a-form-model-item has-feedback label="Email Address" prop="email">
          <a-input
            v-model="form.email"
            :disabled="registering"
            placeholder="Your working Email address"
          />
        </a-form-model-item>
        <a-form-model-item label="Practitioner Type" prop="role">
          <a-select
            v-model="form.role"
            :disabled="registering"
            placeholder="Practitioner Type"
            :filter-option="filterOptionP"
          >
            <a-select-option
              v-for="p in practitioner"
              :key="p.id"
              :value="p.id"
            >
              {{ p.title }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item has-feedback label="Password" prop="password">
          <a-input-password
            v-model="form.password"
            :disabled="registering"
            placeholder="Enter your password"
          />
        </a-form-model-item>
        <a-form-model-item
          has-feedback
          label="Confirm Password"
          prop="confirm_password"
        >
          <a-input-password
            v-model="form.confirm_password"
            :disabled="registering"
            placeholder="Enter your password again"
          />
        </a-form-model-item>
        <a-form-model-item :label="`What is ${add.num1}+${add.num2}?`">
          <a-input
            v-model="resultOfSum"
            type="number"
            autocomplete="off"
            :disabled="registering"
            placeholder="Enter the sum of the above Operation here"
          />
        </a-form-model-item>
        <a-form-model-item>
          <a-button
            class="w-full"
            :loading="registering"
            type="primary"
            @click="onSubmit"
            :disabled="Number(resultOfSum) !== add.num1 + add.num2"
          >
            Sign Up
          </a-button>
        </a-form-model-item>
        <p>
          By clicking on Sign Up, you have agree to our
          <a href="https://legalnaija.com/terms-conditions/" target="_blank"
            >Terms and Condition</a
          >
          and
          <a href="https://legalnaija.com/privacy-policy/" target="_blank"
            >Privacy Policy</a
          >
        </p>

        <p>
          I have an account?
          <router-link to="/signin">Sign In</router-link> here.
        </p>
      </a-form-model>
    </div>
  </div>
</template>
<script>
import authApi from '../api/auth'
export default {
  name: 'SignUp',
  layout: 'auth',
  data() {
    const passwordValidator = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('Please input the password'))
      } else {
        if (this.form.confirm_password !== '') {
          this.$refs.signupForm.validateField('confirm_password')
        }
        callback()
      }
    }
    const confirmPasswordValidator = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('Please input the password again'))
      } else if (value !== this.form.password) {
        callback(new Error("Two inputs don't match!"))
      } else {
        callback()
      }
    }
    return {
      practitioner: [
        {
          id: 'lawyer',
          title: 'Lawyer'
        },
        {
          id: 'lawfirm',
          title: 'Law Firm'
        }
      ],
      registering: false,
      message: '',
      resultOfSum: '',
      add: {},
      error: 'false',
      user_email: '',
      form: {
        email: '',
        password: '',
        role: 'lawyer',
        fname: '',
        lname: '',
        confirm_password: ''
      },
      rules: {
        email: [
          {
            pattern: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
            required: true,
            message: 'Enter a valid email address',
            trigger: 'blur'
          }
        ],
        password: [
          {
            message: 'Password cannot be empty',
            required: true
          },
          {
            message: 'Password must be at least 6 characters',
            min: 6
          },
          {
            trigger: 'change',
            validator: passwordValidator
          }
        ],
        confirm_password: [
          {
            required: true,
            message: 'Enter your password again'
          },
          {
            validator: confirmPasswordValidator,
            trigger: 'change'
          }
        ],
        fname: {
          required: true,
          trigger: 'blur',
          message: 'First name is required'
        },
        lname: {
          required: true,
          trigger: 'blur',
          message: 'Last name is required'
        },
        role: {
          required: true,
          trigger: 'blur',
          message: 'Practitioner type is required'
        }
      }
    }
  },
  head: {
    title: 'Sign Up | Legal Naija'
  },
  created() {
    this.generateNumber()
  },
  methods: {
    async resendVerification() {
      const req = await authApi(this.axios).resendEmailVerification({
        email: this.user_email
      })
      if (req.error) {
        this.$notification.error({
          message: 'Unable to send email',
          description: req.message
        })
        return
      }
      this.$notification.success({
        message: 'Email sent successfully',
        description: req.message
      })
      this.$router.push('/signin')
    },
    async registerUser() {
      this.message = ''
      this.registering = true
      const req = await authApi(this.axios).register({
        firstName: this.form.fname,
        lastName: this.form.lname,
        email: this.form.email,
        password: this.form.password,
        role: this.form.role
      })
      this.user_email = this.form.email
      // this.registering = false
      if (req.error) {
        this.$notification.error({
          message: 'Registration Failed',
          description: req.message
        })
        this.registering = false
        return
      }
      this.error = false
      this.message = 'Registration Successful'
      this.registering = false
      this.form = {}
    },
    onSubmit() {
      this.$refs.signupForm.validate(valid => {
        if (valid) {
          this.registerUser()
        } else {
          return false
        }
      })
    },
    generateNumber() {
      this.add = {
        num1: Math.floor(Math.random() * 10 + 1),
        num2: Math.floor(Math.random() * 10 + 1)
      }
    },
    filterOptionP(input, option) {
      return option.componentOptions.children[0].text
        .toLowerCase()
        .includes(input.toLowerCase())
    }
  }
}
</script>
